@import '../../functions';

.header {
    display: flex;
    height: px_to_rem(87);
    padding: 0 px_to_rem(45);
    background: #DFF1F7;
    font-size: px_to_rem(18);
    color: #164360;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.header_section {
    min-width: px_to_rem(114);
}

.logo {
    stroke: unset;
    fill: unset;
    width: px_to_rem(114);
    height: px_to_rem(52);
    display: block;
}

.tools {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    column-gap: px_to_rem(16);
}

.tool {
    background: transparent;
    padding: 0;
    margin: 0;
    width: px_to_rem(30);
    height: px_to_rem(30);
    border: none;
    cursor: pointer;
    flex: none;
    color: #164360;
    font-weight: 500;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    row-gap: px_to_rem(10);
    column-gap: px_to_rem(50);
    padding: 0 px_to_rem(10);
}

.link { 
    color: #164360;
}

.sidebar_toggler, .sidebar_close_button {
    color: #08263F;
    width: px_to_rem(24);
    height: px_to_rem(24);
    cursor: pointer;
    background: transparent;
    padding: 0;
    border: 0;
}

.sidebar_toggler_icon, .sidebar_close_icon {
    width: px_to_rem(24);
    height: px_to_rem(24);
}

.sidebar_content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100%;
}

.sidebar_header {
    padding: px_to_rem(25);
    flex: none;
}

.sidebar_footer {
    border-top: px_to_rem(2) solid #164360;
    padding: px_to_rem(20) px_to_rem(25);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
}

.sidebar_signout_button {
    column-gap: px_to_rem(5);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    color: #164360;
    font-size: px_to_rem(14);
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    background: transparent;
}

.sidebar_signout_icon {
    width: px_to_rem(16);
    height: px_to_rem(17);
    display: block;
}

[data-is-mobile-view="true"] {
    .header {
        height: px_to_rem(32);
        padding: 0;
        background: transparent;
        font-size: px_to_rem(16);
        color: #164360;
    }
    
    .header_section {
        min-width: px_to_rem(16);
    }

    .header_section:nth-child(2) {
        align-self: flex-start;
    }
    
    .logo {
        width: px_to_rem(60);
        height: px_to_rem(32);
    }

    &[data-page="home"], 
    &[data-page="purchase"]:not([data-use-regular-mobile-header="true"]), 
    &[data-page="login"] {
        .header {
            padding-bottom: max(calc(px_to_rem(129) - (var(--scroll-top) * var(--scrol-anchor) * 1px)), 0px);
        }

        .header_section:nth-child(2) {
            position: relative;
        }

        .logo {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            width: max(calc(px_to_rem(208) - (var(--scroll-top) * var(--scrol-anchor) * 0.5px)), px_to_rem(60));
            height: max(calc(px_to_rem(111) - (var(--scroll-top) * var(--scrol-anchor) * 0.6px)), px_to_rem(32));
            margin-top: max(calc(px_to_rem(50) - (var(--scroll-top) * var(--scrol-anchor) * 0.4px)), 0px);
        }
    }

    .links .tool {
        display: block;
        width: unset;
        height: unset;
        font-weight: 400;
        font-size: px_to_rem(16);
    }
    
    .links {
        row-gap: px_to_rem(50);
        column-gap: px_to_rem(0);
        padding: px_to_rem(25);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        .header_section:nth-child(1) {
            text-align: #{$right};
        }

        .header_section:nth-child(2) {
            text-align: center;
        }

        .header_section:nth-child(3) {
            text-align: #{$left};
        }

        .sidebar_header {
            direction: #{$direction};
        }

        .sidebar_footer {
            direction: #{$direction};
        }

        .sidebar_signout_icon {
            transform: if-rtl(scaleX(1), scaleX(-1));
        }

        &[data-is-mobile-view="true"] {
            .links .link {
                padding-#{$left}: px_to_rem(50);

                span {
                    justify-content: #{$right};
                }
            } 

            .links .tool {
                padding-#{$left}: px_to_rem(50);
                text-align: #{$right};
            }
        }

        &[data-page="home"][data-is-mobile-view="false"],  
        &[data-page="login"][data-is-mobile-view="false"] {
            .header {
                @include home_page_pc_bg($direction);
            }
        }

        &[data-page="purchase"][data-is-mobile-view="false"] {
            .header {
                @include home_page_pc_path_bg($direction);
            }
        }
    }
}