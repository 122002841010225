@import '../../functions';

.loader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: transparent;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.loader::before {
    content: '';
    display: block;
    width: px_to_rem(50);
    height: px_to_rem(50);
    border-radius: 50%;
    display: inline-block;
    border-top: px_to_rem(3) solid #ffffff;
    border-right: px_to_rem(3) solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
} 

.use_mask {
    pointer-events: all;
    background: rgba(0, 0, 0, 0.2);
}