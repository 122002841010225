@import '../../functions';

.landscape {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.74);
    backdrop-filter: blur(px_to_rem(5.6));
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.text {
    color: #000000;
    text-align: center;
    font-size: px_to_rem(20);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}