@import '../../functions';

.button {
  display: flex;
  height: px_to_rem(50);
  max-width: 100%;
  box-sizing: border-box;
  padding: px_to_rem(20) px_to_rem(12);
  justify-content: center;
  align-items: center;
  gap: px_to_rem(10);
  flex-shrink: 0;
  border-radius: px_to_rem(10);
  border: none;
  background: transparent;
  color: #164360;
  font-size: px_to_rem(18);
  font-weight: 500;
  cursor: pointer;
}

.button_premium {
  width: px_to_rem(300);
  color: #ffffff;
  background: #164360;
}

[data-is-mobile-view="true"] {
  .button {
    height: px_to_rem(45);
  }

  .button_premium {
      height: px_to_rem(56);
  }
}