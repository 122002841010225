@import '../../functions';

.sidebar_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 1;
}

.sidebar_mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: rgba(255, 255, 255, 0.40);
    -webkit-backdrop-filter: blur(px_to_rem(2));
    backdrop-filter: blur(px_to_rem(2));
    transition: opacity 0.25s ease-in-out;
    will-change: opacity;
}

.sidebar_content {
    background: #F8F8F8;
    box-shadow: 0 0 0 0 transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-y: hidden;
    overflow-x: auto;
    max-width: 100%;
}

.sidebar_content_transition {
    transition: transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
    will-change: transform, box-shadow;
}

.sidebar_content_left {
    left: 0;
    transform: translate(-100%, 0);
}

.sidebar_content_right {
    right: 0;
    transform: translate(100%, 0);
}

.sidebar_wrapper_open {
    pointer-events: all;

    .sidebar_mask {
        opacity: 1;
    }

    .sidebar_content {
        transform: translate(0, 0);
        box-shadow: px_to_rem(2) px_to_rem(17) px_to_rem(38) 0 rgba(0, 0, 0, 0.10), 
                    px_to_rem(10) px_to_rem(69) px_to_rem(70) 0 rgba(0, 0, 0, 0.09), 
                    px_to_rem(21) px_to_rem(155) px_to_rem(94) 0 rgba(0, 0, 0, 0.05), 
                    px_to_rem(38) px_to_rem(276) px_to_rem(112) 0 rgba(0, 0, 0, 0.01), 
                    px_to_rem(-2) px_to_rem(17) px_to_rem(38) 0 rgba(0, 0, 0, 0.10), 
                    px_to_rem(-10) px_to_rem(69) px_to_rem(70) 0 rgba(0, 0, 0, 0.09), 
                    px_to_rem(-21) px_to_rem(155) px_to_rem(94) 0 rgba(0, 0, 0, 0.05), 
                    px_to_rem(-38) px_to_rem(276) px_to_rem(112) 0 rgba(0, 0, 0, 0.01);
    }
}