@import '../../functions';

.container {
    position: fixed;
    border: 1px solid #DFDFDF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.19), 0px 6px 6px rgba(0, 0, 0, 0.23);
    border-radius: px_to_rem(10);
    overflow: hidden;
    background: #ffffff;
    z-index: 1;
}

.tools {
    position: relative;
    height: px_to_rem(40);
    min-width: px_to_rem(80);
}

.touch_controller {
    width: 100%;
    height: 100%;
    cursor: move;
}

.close {
    position: absolute;
    top: 0;
    bottom: 0;
    width: px_to_rem(40);
    cursor: pointer;
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
}

.close_icon {
    color: #08263F;
    width: 60%;
    height: 60%;
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        .close {
            #{$right}: 0;
        }
    }
}