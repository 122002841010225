@import '../../functions';

.container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;
}

.mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.10);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    will-change: opacity;
}

.alert {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100vw - px_to_rem(40));
    @include item_box_shadow();
    border-radius: px_to_rem(20);
    overflow: hidden;
    background: #ffffff;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    will-change: opacity;
}

.container_open {
    pointer-events: all;

    .mask, .alert {
        opacity: 1;
    }
}

.tools {
    position: relative;
    height: px_to_rem(40);
    min-width: px_to_rem(80);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
}

.close {
    width: px_to_rem(40);
    height: px_to_rem(40);
    cursor: pointer;
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
}

.close_icon {
    color: #08263F;
    width: 60%;
    height: 60%;
}

.message {
    padding: px_to_rem(10) px_to_rem(55) px_to_rem(50) px_to_rem(55);
    font-size: px_to_rem(24);
    font-weight: 700;
    color: #000000;
    font-style: normal;
    line-height: normal;
    width: max-content;
    max-width: 100%;
    box-sizing: border-box;
}

[data-is-mobile-view="true"] {
    .mask {
        background: rgba(0, 0, 0, 0.5);
    }

    .alert {
        top: unset;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: unset;
        min-height: px_to_rem(200);
        max-height: 75svh;
        border: none;
        box-shadow: none;
        border-radius: px_to_rem(20) px_to_rem(20) 0 0;
        opacity: 1;
        transform: translate(0, 100%);
        transition: transform 0.3s ease-in-out;
        will-change: transform;
        padding: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
    }

    .container_open {
        .alert {
            transform: translate(0, 0%);
        }
    }

    .tools {
        flex: none;
    }

    .message {
        flex: 1;
        font-size: px_to_rem(18);
        padding: px_to_rem(0) px_to_rem(16) px_to_rem(24) px_to_rem(16);
        width: 100%;
        text-align: center;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .light_mask{
        background: rgba(0, 0, 0, 0.03);
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        .container {
            direction: #{$direction};
        }
    }
}