@import '../../functions';

.wrap_block {
    &.ltr .block_img {
        animation-name: transform-ltr;
    }

    &.rtl .block_img {
        animation-name: transform-rtl;
    }
}

.wrap_block {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.block_img {
    display: flex;
    gap: 5rem;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    transform: translate(0%, 0);
    width: fit-content;
    animation-duration: 25s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes transform-ltr {
    from {
        transform: translate(0%, 0);
    }

    to {
        transform: translate(50%, 0) translate(2.5rem, 0);
    }
}

@keyframes transform-rtl {
    from {
        transform: translate(50%, 0);
    }

    to {
        transform: translate(0%, 0) translate(-2.5rem, 0);
    }
}