@import '../../../functions';

.about {
    width: px_to_rem(1360);
    max-width: 100%;
    margin: auto;
    padding: px_to_rem(56) px_to_rem(10) px_to_rem(26) px_to_rem(10);
    box-sizing: content-box;
    grid-template-columns: auto 1fr;
    column-gap: px_to_rem(50);
    row-gap: 0;
    display: grid;
    align-content: stretch;
    justify-content: space-between;
    align-items: center;
    justify-items: start;
}

.topic {
    display: contents;
}

.topic_title {
    width: min-content;
    line-height: 0.87;
    font-size: px_to_rem(128);
    padding: px_to_rem(44) 0;
}

.topic_content {
    color: #000000;
    font-size: px_to_rem(18);
    padding: px_to_rem(44) px_to_rem(19);
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        height: 2px;
        background: #B8DEE9;
        border-radius: 2px;
    }
}

.topic:last-child .topic_content::after {
    display: none;
}

@media (max-width: 1160px) { 
    .about {
        column-gap: px_to_rem(30);
        column-gap: min(px_to_rem(50), 4.2vw);
    }

    .topic_title {
        font-size: px_to_rem(96);
    }
}

@media (max-width: 1000px) { 
    .about {
        grid-template-columns: 1fr;
    }

    .topic_title {
        width: 100%;
        text-align: center;
        padding: px_to_rem(44) 0 0 0
    }
}

[data-is-mobile-view="true"] {
    .about {
        padding: 0;
        box-sizing: content-box;
        grid-template-columns: auto;
    }
    
    .topic_title {
        color: #164360;
        display: block;
        padding: px_to_rem(30) 0 px_to_rem(10) 0;
        border-bottom: 1px solid currentColor;
        box-sizing: border-box;


        & > button {
            color: currentColor;
            position: relative;
            display: block;
            cursor: pointer;
            width: 100%;
            box-sizing: border-box;
            line-height: normal;
            font-size: px_to_rem(18);
            font-weight: 700;
            margin: 0;
            padding: 0;
            border: none;
            background: transparent;

            &::before, &::after {
                content: '';
                width: px_to_rem(10);
                height: px_to_rem(2);
                display: block;
                background: currentColor;
                position: absolute;
                top: 50%;
                transform-origin: center center;
                transform: translate(0, -50%);
            }

            &::after {
                transform: translate(0, -50%) rotate(90deg);
                transition: transform 0.75s ease-in-out;
                will-change: transform;
            }
        }
    }
    
    .topic_content {
        color: #164360;
        font-size: px_to_rem(18);
        padding: 0;
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.75s ease-in-out;
        will-change: grid-template-rows;

        & > div {
            padding: px_to_rem(21) 0 0 0;
            overflow: hidden;
            box-sizing: border-box;
        }
    
        &::after {
            display: none;
        }
    }

    .topic.topic_selected {
        .topic_title {
            & > button {
                &::after {
                    transform: translate(0, -50%) rotate(0deg);
                }
            }
        }

        .topic_content {
            grid-template-rows: 1fr;
        }
    }

    .topic:first-child {
        .topic_title {
            padding-top: px_to_rem(25);
        }
    }

    .topic:last-child {

    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-dynamic_page_direction='#{$direction}'] {
        .topic_content {
            ul {
                padding-#{$right}: 1em;
            }
        } 
    }

    [data-is-mobile-view="true"] {
        [data-dynamic_page_direction='#{$direction}'] {
            .topic_title {
                & > button {
                    padding-#{$right}: px_to_rem(20);
                    text-align: #{$right};
    
                    &::before, &::after {
                        #{$right}: 0;
                    }
                }
            }
    
            .topic_content {
                ul {
                    padding-#{$right}: 1.25em;
                }
            }
        }
    }
}