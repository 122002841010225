@import '../../functions';

.page_header {
    position: relative;
    background-image: url('../../images/bg.jpg');
    background-position: top right;
    background-repeat: repeat;
    color: #164360;
}

.page_header_content {
    min-height: px_to_rem(258);
    box-sizing: border-box;
    position: relative;

    &::before {
        content: '';
        display: block;
        height: 1px;
    }
}

[data-is-mobile-view="true"] {
    .page_header_content {
        min-height: px_to_rem(30);
        padding: px_to_rem(22) px_to_rem(12) px_to_rem(40) px_to_rem(12);
    }
}