@import '../../../functions';

.items {
    width: px_to_rem(1041);
    max-width: 100%;
    margin: px_to_rem(52) auto 0 auto;
    column-gap: px_to_rem(27);
    row-gap: px_to_rem(39);
    display: grid;
    justify-items: stretch;
    align-items: stretch;
    justify-content: space-between;
    align-content: space-between;
}

.item {
    @include item_box_shadow;
    display: grid;
    grid-template-rows: subgrid;
    grid-row-end: span 3;
    row-gap: px_to_rem(10);
    padding: px_to_rem(8) px_to_rem(16);
    color: #000000;
    font-size: px_to_rem(18);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

[data-is-mobile-view="true"] {
    .items {
        margin: px_to_rem(12) auto 0 auto;
        row-gap: px_to_rem(21);
    }

    .item {
        padding: px_to_rem(10) px_to_rem(12);
        font-size: px_to_rem(14);
    }
}