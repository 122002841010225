.controller {
    position: absolute;
    pointer-events: none;
    user-select: none;
    opacity: 1;
    border: 0;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -9999;
}