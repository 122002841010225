@import '../../functions';

.layout {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;
    position: relative; 
    background: #ffffff;
    box-shadow: 0 px_to_rem(25) px_to_rem(20) px_to_rem(-20) rgba(0, 0, 0, 0.15);
    z-index: 1;
}

.outer_section:empty {
    display: none;
}

.sticky_content {
    position: sticky;
    top: 0;
    flex: none;
    z-index: 1;
    background-color: #ffffff;

    &:has(> .outer_sticky_section:empty) {
        background-color: transparent;
    }
}

.sticky_section_separator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0px;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
}

.page_header {
    position: relative;
    background-image: url('../../images/bg.jpg');
    background-position: top right;
    background-repeat: repeat;
}

.page_header_content {
    min-height: px_to_rem(258);
}

.page_title {
    margin-top: px_to_rem(52);
    text-align: center;
    font-size: px_to_rem(96);
    font-weight: 900;
    font-style: normal;
    line-height: normal;
    padding: 0 px_to_rem(16);

    span {
        line-height: 0.88;
        display: inline-block;
    }
}

.page_sub_title {
    text-align: center;
    font-size: px_to_rem(24);
    font-weight: 500;
    color: #164360;
    font-style: normal;
    line-height: normal;
    position: relative;
    padding: 0 px_to_rem(16);

    span {
        white-space: nowrap;
        display: inline-block;
    }
}

.page_content {
    flex: 1;
    padding: px_to_rem(20) px_to_rem(20) px_to_rem(40) px_to_rem(20);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    box-sizing: border-box;
    overflow: hidden;
}

[data-is-mobile-view="true"] {
    .page_title {
        margin-top: px_to_rem(45);
        font-size: px_to_rem(48);
        font-weight: 700;
        color: #DFF1F7;
        padding: 0;
    }
    
    .page_sub_title {
        margin-top: px_to_rem(15);
        font-size: px_to_rem(16);
        color: #000000;
        font-weight: 400;
        padding: 0;
    }

    &[data-page="home"] {
        --scrol-anchor: 0.65;
    }

    &[data-page="purchase"]:not([data-use-regular-mobile-header="true"]), 
    &[data-page="login"] {
        --scrol-anchor: 1;
    }

    &[data-page="home"], 
    &[data-page="purchase"]:not([data-use-regular-mobile-header="true"]), 
    &[data-page="login"] {
        .sticky_content {
            margin-bottom: min(calc((var(--scroll-top) * var(--scrol-anchor) * 1px) - px_to_rem(129)), 0px);
        }

        .page_content {
            margin-top: max(px_to_rem(129), 0px);
        }
    }
}

[data-use-regular-mobile-header="true"] {
    .page_title {
        margin-top: px_to_rem(20);
    }

    &[data-is-mobile-view="true"] {
        .page_title {
            margin-top: px_to_rem(20);
        }
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        &[data-page="home"][data-is-mobile-view="false"], 
        &[data-page="login"][data-is-mobile-view="false"] {
            .layout {
                @include home_page_pc_bg($direction);
            }
        }

        &[data-page="purchase"][data-is-mobile-view="false"] {
            .layout {
                @include home_page_pc_path_bg($direction);
            }
        }
    }
}

