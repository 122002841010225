@import '../../functions';

.fild {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
}

.label {
    color: #000000;
    font-size: px_to_rem(16);
    font-weight: 400;
}

.error {
    color: #ff3333;
    font-size: px_to_rem(14);
    font-weight: 400;
    height: px_to_rem(16);
    line-height: px_to_rem(16);
    padding: 0 px_to_rem(12);
    width: 10px;
    white-space: nowrap;
    overflow: visible;
}

.input {
    position: relative;
    height: px_to_rem(45);
    width: 100%;

    input {
        color: #08263F;
        position: absolute;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0 px_to_rem(12);
        border-radius: px_to_rem(10);
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: #ffffff;
        box-sizing: border-box;
        outline: none;
        font-size: px_to_rem(14);
        font-weight: 400;
        transition: border-color 0.2s ease-in-out;

        &::placeholder {
            color: #08263F;
            opacity: 0.4;
        }
    }
}

.fild_error .input input {
    border-color: #ff3333;
}


[data-is-mobile-view="true"] {
    
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        .input {
            input[type="email"], 
            input[type="tel"] {
                direction: ltr;
                
                &[value=""] {
                    direction: $direction;
                }
            }
        }
    }
}