@import '../../../functions';

.container {
    text-align: center;
}

.title {
    color: #000000;
    font-size: px_to_rem(24);
    font-weight: 700;
    margin-bottom: px_to_rem(46);
}

.buttons {
    row-gap: px_to_rem(10);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.close_button {
    width: px_to_rem(276);
    height: px_to_rem(56);
    max-width: 100%;
}

[data-is-mobile-view="true"] {
    .title {
        color: #08263F;
        font-size: px_to_rem(18);
        margin-bottom: px_to_rem(16);
    }
}