@import '../../functions';

.signup {
    @include item_box_shadow();
    border-radius: px_to_rem(20);
    background: #ffffff;
    padding: px_to_rem(50) px_to_rem(55);
    width: px_to_rem(766);
    max-width: 100%;
    margin: 0 auto;
}

.error {
    color: #ff3333;
    font-size: px_to_rem(14);
    font-weight: 400;
    min-height: px_to_rem(16);
    line-height: px_to_rem(16);
    padding: 0 px_to_rem(12);
}

.ltr_data {
    direction: ltr;
    display: inline-block;
}

.form {
    column-gap: px_to_rem(32);
    row-gap: px_to_rem(32);
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.terms_use {
    grid-column: 1 / span 2; 

    & > div:nth-child(1) {
        color: #000000;
        font-size: px_to_rem(16);
        font-weight: 400;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    }

    label {
        column-gap: px_to_rem(10);
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
    }

    input {
        appearance: none;
        -webkit-appearance: none;
        position: relative;
        display: flex;
        align-content: center;
        justify-content: center;
        padding: px_to_rem(2);
        margin: 0;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: px_to_rem(5);
        width: px_to_rem(20);
        height: px_to_rem(20);
        cursor: pointer;

        &::before {
            content: "\2714";
            transform: scale(0);
            color: #ffffff;
            font-size: px_to_rem(17);
            top: px_to_rem(-5);
            position: relative;
        }

        &:checked {
            background-color: #164360;
        }

        &:checked::before {
            transform: scale(1);
        }
    }

    a {
        color: #164360;
        text-decoration: underline;
    }
}

.buttons {
    margin-top: px_to_rem(18);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.button {
    width: px_to_rem(313);
    height: px_to_rem(71);
    font-size: px_to_rem(22);
    max-width: 100%;
}

.cancel_button {
    margin-top: px_to_rem(14);
}

.signup_confirm {
    .form {
        display: grid;
        grid-template-columns: 1fr;
    }

    > div {
        max-width: px_to_rem(511);
        margin: 0 auto;
    }
}

.confirm_text {
    color: #000000;
    font-size: px_to_rem(16);
    font-weight: 400;
    margin-bottom: px_to_rem(35);
    text-align: center;
}

.resend_code {
    color: #164360;
    font-size: px_to_rem(16);
    font-weight: 500;
    margin-top: px_to_rem(16);
    margin-bottom: px_to_rem(150);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    .resend_code_button {
        font-size: px_to_rem(16);
        padding: 0;
        height: px_to_rem(17);

        &:hover {
            text-decoration: underline;
        }
    }
}

[data-is-mobile-view="true"] {
    .signup {
        box-shadow: none;
        border: none;
        border-radius: 0;
        padding: 0;
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
    }

    .form {
        display: grid;
        grid-template-columns: 1fr;
    }

    .terms_use {
        grid-column: 1 / span 1;
    }

    .button {
        height: px_to_rem(45);
        font-size: px_to_rem(16);
    }

    .resend_code {
        font-size: px_to_rem(14);
        margin-top: px_to_rem(12);
        margin-bottom: px_to_rem(20);
    
        .resend_code_button {
            font-size: px_to_rem(14);
        }
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        
    }
}