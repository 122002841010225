/*@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap');

:root {
  --link-color: #0279CF;
  --scroll-top: 0;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

#root {
  height: 100vh;
  height: 100svh;
  overflow: hidden;
  position: relative;
  /*font-family: 'Heebo', sans-serif;*/
  font-family: 'Assistant', sans-serif;
  font-optical-sizing: auto;
  color: #000000;
}

a, button, input, select {
  font-family: 'Assistant', sans-serif;
  color: #000000;
}

#outer_layers {
  display: contents;
  width: 0;
  height: 0;
}

[data-is-desktop="true"] {
  ::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
  }
    
  ::-webkit-scrollbar-track {
      border-radius: 1rem;
      margin: 0;
      background-color: #ececf0;
  }
    
  ::-webkit-scrollbar-thumb {
      background-color: #7f7f90;
      outline: 1px solid #ececf0;
      border-radius: 1rem;
  }
}