@import '../../functions';


.container {
    display: flex;
    max-width: 100%;
    width: 57rem;
    margin: 0 auto;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    height: 40rem;

}

.content {
    flex: none;
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: px_to_rem(50);
    flex-grow: 1;
}

::selection {
    background-color: #164360;
    color: white;
}

.nav {
    @include item_box_shadow;
    color: var(--new-blue, #164360);
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    justify-content: space-between;
    height: 3.5rem;
    padding: 0.2rem 0;

    button {
        all: unset;
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        height: 100%;
        width: 100%;
        box-sizing: content-box;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        box-sizing: content-box;

        &::before {
            position: absolute;
            top: 25%;
            height: 50%;
            width: 0.15rem;
            background-color: #164360;
            content: '';
            opacity: 0.2;
        }

        &:last-child::before {
            content: none;
        }

        .tow_svg {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: auto;
            direction: rtl;
        }

        .one_svg {
            margin: auto;
            justify-content: center;
        }
    }
}

.text_box {
    margin-top: 1.5rem;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    @include item_box_shadow;
    position: relative;
    z-index: 0;
    direction: ltr;
    flex-grow: 1;
}

.user_text::-webkit-scrollbar {
    width: 0.2rem;
}

.user_text::-webkit-scrollbar-track {
    background: none;
}

.user_text::-webkit-scrollbar-thumb {
    height: 1rem;
    min-height: 2rem;
    background: rgba(14, 64, 107, 0.20);

}

.wordsbutton {
    background-color: red;
    width: 200%;
}

.user_text {
    width: 98.5%;
    height: 97%;
    border: none;
    resize: none;
    outline: none;
    color: #000;
    text-align: right;
    font-family: Assistant;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    white-Space: pre-wrap;
    overflow-y: scroll;
    padding-right: 0.7rem;
    direction: rtl;
}


.user_text:disabled {
    background-color: white;
}

.dark_botton_submit {
    background-color: #164360;
    color: white;
    width: 25%;
    margin: 1.5rem auto;
}

.instructions {
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 1rem 1rem;
    height: 2.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: #164360;
    margin-top: -0.5rem;
    border-top: #000;
    z-index: 2;
    position: relative;
    background-color: white;
    border: 0.1875rem solid #F8F8F8;
    border-top: none;
}

.help_pop_up {
    color: #164360;
    font-size: 1rem;
    opacity: 0.8;
    text-align: left;
    font-weight: 400;
    width: 580px;
    max-width: 100%;

    ul {
        margin: 5px 16px;
        padding: 0 20px;
    }

    .header {
        font-weight: 700;
        margin: 0.2rem;
        text-decoration: underline;
    }

    .block_explain {
        margin-bottom: 20px;
    }

    li {
        span {
            font-weight: 600;
        }

        margin-bottom: 0.2rem;
    }

    .close_button {
        margin: 0 auto;
    }
}

.word_vocalization {
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 1rem 1rem;
    height: 20rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -1rem;
    border-top: #000;
    background-color: white;
    z-index: 3;
    position: relative;
}

.nakdan_More_options {
    margin: 2rem 1rem;
    border-bottom: 1px solid #ebe1e8;
    display: flex;
    flex-direction: row;
    width: 96%;
    height: 3rem;

    span {
        color: #164360;
        font-weight: 500;
        width: 20%;
    }
}

.other_options {
    display: flex;
    justify-content: flex-start;
    line-height: 1.5rem;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
}

.button {
    all: unset;
    margin: 0 1rem;
    font-size: 1.2rem;
}

.button:focus,
.button:active {
    font-weight: 700;
    color: #164360;
}

.nakdan_Manual_Vocalization_extended {
    width: 96%;
    margin: 0 1rem 1rem 1rem;

    span {
        color: #164360;
        font-weight: 500;
    }

    .wrap_view_word {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;

        .wiew_word {
            border-radius: 7px;
            border: 1px solid #D9D9D9;
            height: 2rem;
            width: 90%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            direction: rtl;

            .one_letter {
                all: unset;
                color: #000;
                text-align: center;
                font-family: Assistant;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding: 0 0.4rem;
            }

            .bold_one_letter {
                font-weight: 700;
                color: white;
                background-color: #164360;

            }
        }

    }


    .letters {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        direction: rtl;

        .letter {
            padding: 0;
            border-radius: 4px;
            border: 1px solid #D9D9D9;
            height: 2rem;
            width: 2rem;
            display: flex;
            justify-content: center;
            text-align: center;
            color: #000;
            font-size: 1.1rem;
            font-weight: 400;
            margin-left: 0.8rem;
            margin-bottom: 0.5rem;
        }

        .dark_letter {
            opacity: 0.3;
        }
    }
}

.buttons_replace {
    height: 2.5rem;
    width: 97%;
    display: flex;
    justify-content: flex-end;

}

.replace_button_submit {
    width: 8rem;
    height: 100%;
}

.cancel_button_submit {
    width: 8rem;
    background-color: white;
    color: #164360;
    height: 100%;
}

[data-is-mobile-view="true"] {
    .container {
        padding: 0 0 0 0;
        height: 27rem;
    }

    .content {
        margin-top: px_to_rem(7);
        padding: 0 0 0 0;
    }

    .text_box {
        box-shadow: 0 0 0 0;
        border: 0.08rem solid #E5E5E5;
        margin-top: -0.5rem;
        flex-grow: 1;
    }

    .user_text {
        color: #164360;
        font-size: 1.2rem;
        user-select: text;

    }

    .dark_botton_submit {
        width: 100%;
        margin: 1rem auto;
    }

    .nav {
        margin-top: 1rem;
        box-shadow: 0 0 0 0;
        border: none;
        border-radius: 0;
        margin-bottom: 1rem;
        margin-top: -1.5rem;

        button {
            &::before {
                content: none;
            }
        }
    }

    .instructions {
        box-shadow: 0 0 0 0;
        border: 0.08rem solid #E5E5E5;
        border-top: 0.08rem solid #E5E5E5;
        height: 2rem;
        margin-top: -0.5rem;

        span {
            font-size: 0.8rem;
        }
    }

    .word_vocalization {
        height: 100%;
        margin-top: 1rem;
        width: 100%;
        border-radius: 10%;
        border: none;
        box-shadow: none;


        .pull_bar {
            width: 80px;
            height: 4px;
            flex-shrink: 0;
            border-radius: 100px;
            background: rgba(14, 64, 107, 0.2);
            margin: 1.5rem auto;
            margin-top: 0;
        }
    }

    .alert {
        .tools {
            display: none;
        }
    }

    .nakdan_More_options {
        align-items: center;
        padding-bottom: 0.5rem;
        margin: 0.3rem 1rem 1rem 1rem;
        justify-content: space-between;
        width: 92%;
        font-size: 1rem;

        span {
            width: 35%;
        }
    }

    .other_options {
        margin: 1rem 0;
        justify-content: space-between;
        width: 60%;
        padding: 0 0;
        box-sizing: content-box;
        text-align: center;
    }

    select {
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        width: 100%;
        color: black;
        padding-right: 0.5rem;
        height: 2rem;
        font-size: 1rem;
        padding-left: 0.3rem;
        direction: rtl;
        max-height: 200px;
        /* גובה מקסימלי */
        overflow-y: auto;

    }

    .replace_button_submit {
        width: 93%;
        margin: 0 auto;
        height: 2rem;
    }

    .wrap_view_word {
        padding-left: 1rem;

        .wiew_word {
            width: 95%;

            .one_letter {
                padding: 0 0.2rem;
            }
        }
    }

    .nakdan_Manual_Vocalization_extended {
        margin: 0 1rem 1rem 1rem;

        span {
            margin-bottom: 1rem;
            width: 50%;
        }

        .wrap_view_word {
            flex-direction: column;
            align-items: flex-start;
            padding-left: 0;
        }
    }


    .letters {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -3%;

        .letter {
            width: 1.8rem;
            height: 1.8rem;
            margin-left: 0.4rem;
            margin-bottom: 0.4rem;
            text-align: center;
            align-items: center;
        }
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        .nav {
            button::before {
                #{$left}: 0;
            }

        }


        &[data-is-mobile-view="true"] {
            .input {
                padding-#{$right}: px_to_rem(40);
            }
        }

        .nakdan_More_options {
            span {
                text-align: #{$right};
            }
        }

        .nakdan_Manual_Vocalization_extended {
            margin: 0 1rem 1rem 1rem;

            span {
                text-align: #{$right};
            }
        }

        .help_pop_up {
            text-align: $right;
        }

    }

    [data-direction=ltr] {
        &[data-is-mobile-view="false"] {
            .nakdan_Manual_Vocalization_extended {
                span {
                    width: 25%;
                }
            }
        }
    }
}

.empty {
    height: 7rem;
    width: 100%;
}