@import '../../functions';

.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.content {
    flex: none;
    flex: 1;
    width: 100%;
    margin-top: px_to_rem(50);
}

.length_selector {
    width: min-content;
    max-width: 100%;
    margin: 0 auto px_to_rem(58) auto;
}

.length_selector_title {
    font-size: px_to_rem(20);
    font-weight: 400;
    margin-bottom: px_to_rem(6);
    white-space: nowrap;
}

.crossword_input {
    position: relative;
    direction: rtl;
    column-gap: px_to_rem(15);
    column-gap: min(px_to_rem(15), 1.5vw);
    row-gap: px_to_rem(10);
    margin: 0 auto;
    width: fit-content;
    max-width: 100%;
    display: grid;
}

.crossword_input_letter {
    width: px_to_rem(65);
    height: px_to_rem(65);
    padding: 0;
    margin: 0;
    text-align: center;
    border-radius: px_to_rem(10);
    border: 1px solid #000000;
    box-sizing: border-box;
    font-size: px_to_rem(24);
    font-weight: 500;
    flex: none;
    position: relative;

    &::placeholder {
        color: rgba(0, 0, 0, 0.20);
        font-size: px_to_rem(14);
        line-height: 206%;
        display: block;
        position: absolute;
        top: px_to_rem(3);
        right: px_to_rem(10);
    }
}

$columns: "auto";
@for $length from 2 through 16 {
    $columns: append($columns, #{" auto"});
    
    .crossword_input[data-number_of_letters='#{$length}'] {
        grid-template-columns: #{$columns};

        .crossword_input_letter {
            max-width: calc((100vw - px_to_rem(40) - (px_to_rem(15) * #{$length - 1})) / #{$length});
            max-width: calc((100vw - px_to_rem(40) - (min(px_to_rem(15), 1.5vw) * #{$length - 1})) / #{$length});
        }
    }
}

.buttons {
    flex: none;
    max-width: 100%;
    position: relative;
    margin-top: px_to_rem(20);
    row-gap: px_to_rem(11);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.results {
    @include item_box_shadow();
    flex: none;
    direction: rtl;
    margin: px_to_rem(50) auto 0 auto;
    padding: px_to_rem(30);
    width: px_to_rem(780);
    max-width: 100%;
    color: var(--link-color);
    font-size: px_to_rem(20);
    font-weight: 400;
    font-style: normal;
    line-height: normal;
}

.partial_list {
    color: #000000;
}

.no_results {
    color: #164360;
    font-size: px_to_rem(20);
    font-weight: 400;
    text-align: center;
}

[data-is-mobile-view="true"] {
    .content {
        margin-top: px_to_rem(30);
    }

    .length_selector {
        margin: 0 auto px_to_rem(50) auto;
    }
    
    .length_selector_title {
        font-size: px_to_rem(16);
        margin-bottom: px_to_rem(16);
    }

    .crossword_input {
        column-gap: px_to_rem(6);
    }
    
    .crossword_input_letter {
        width: px_to_rem(50);
        height: px_to_rem(50);
        border-radius: px_to_rem(8);
        border: 1px solid #D9D9D9;

        &::placeholder {
            font-size: px_to_rem(10);
            top: px_to_rem(2);
            right: px_to_rem(5);
        }
    }
    
    $columns: "auto";
    @for $length from 2 through 16 {
        @if $length < 9 {
            $columns: append($columns, #{" auto"});
        }

        .crossword_input[data-number_of_letters='#{$length}'] {
            grid-template-columns: #{$columns};
            
            @if $length < 8  {
                .crossword_input_letter {
                    max-width: calc((100vw - px_to_rem(40) - (px_to_rem(6) * #{$length - 1})) / #{$length});
                }
            }

            @if $length >= 8  {
                .crossword_input_letter {
                    max-width: calc((100vw - px_to_rem(40) - (px_to_rem(6) * 7)) / 8);
                }
            }

            @if $length > 8 {
                &::before {
                    content: '-';
                    height: px_to_rem(50);
                    line-height: px_to_rem(50);
                    font-size: px_to_rem(14);
                    font-weight: 500;
                    display: inline-block;
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    left: px_to_rem(-10);
                    transform: translate(-100%, 0);
                }
            }
        }
    }

    .results {
        margin-top: px_to_rem(30);
        font-size: px_to_rem(14);
        padding: px_to_rem(20);
    }
}