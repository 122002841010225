@import '../../functions';

.container {
  
}

.content {
    
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-dynamic_page_direction='#{$direction}'] {
        &.container {
            direction: #{$dir};
        }
    }
}