@import '../../functions';

.keyboard_toggle {
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
    flex: none;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    position: relative;

    &[disabled] {
        opacity: 0.5;
        cursor: default;
    }
}

.icon {
    position: relative;
    width: 100%;
    height: 100%;
}