@import '../../functions';

.container {
    position: relative;
    width: px_to_rem(253);
    height: px_to_rem(54);
    max-width: 100%;
    box-sizing: border-box;
    color: #000000;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius:  px_to_rem(5);
    border: 1px solid rgba(66, 80, 102, 0.40);
    background: #ffffff;
    box-shadow: 0 px_to_rem(2) px_to_rem(4) 0 rgba(66, 80, 102, 0.10);
    display: inline-block;
}

.selector {
    font-size: px_to_rem(20);
    padding: px_to_rem(12);
    position: relative;
    border: none;
    outline: none;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    margin: 0;
    display: block;
    width: 100%;
    height: 100%;
    color: currentColor;
    cursor: pointer;
}

.arrow {
    position: absolute;
    width: px_to_rem(16);
    height: px_to_rem(16);
    top: 50%;
    transform: translate(0, -50%);
}

.arrow_icon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

[data-is-mobile-view="true"] {
    .container {
        width: px_to_rem(185);
        height: px_to_rem(45);
        border-radius:  px_to_rem(10);
        border: 1px solid rgba(0, 0, 0, 0.10);
    }
    
    .selector {
        font-size: px_to_rem(14);
        height: 100%;
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        .selector {
            padding-#{$left}: px_to_rem(40);
        }

        .arrow {
            #{$left}: px_to_rem(12);
        }
    }
}