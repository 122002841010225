@import '../../functions';

.form {
    position: relative;
    width: 100%;
    height: px_to_rem(76);
    padding: 0;
    margin: 0;
    display: inline-block;

    form {
        width: 100%;
        height: 100%;
    }
}

.input {
    width: 100%;
    height: 100%;
    padding: px_to_rem(10) px_to_rem(17);
    padding-left: px_to_rem(72);
    margin: 0;
    box-sizing: border-box;
    border-radius: px_to_rem(10);
    border: 1px solid #DFDFDF;
    background: #ffffff;
    color: #6A6A6A;
    font-family: 'Assistant', sans-serif;
    font-size: px_to_rem(32);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
    text-align: right;
}

.tools {
    position: absolute;
    padding: 0 px_to_rem(16);
    width: px_to_rem(72);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.tool {
    background: transparent;
    padding: 0;
    margin: 0;
    width: px_to_rem(40);
    height: px_to_rem(40);
    border: none;
    cursor: pointer;
    flex: none;
    display: flex;
    color: #164360;
}

.tool_icon {
    width: px_to_rem(40);
    height: px_to_rem(40);
}

[data-search_input_theme="full_analyze"] {
    .form {
        height: 100%;
    }

    .input {
        font-size: px_to_rem(20);
        padding-right: px_to_rem(60);
    }

    .tools {
        padding: 0 px_to_rem(10);
        width: px_to_rem(38);
        left: unset;
        right: 0;
    }

    .tool {
        width: px_to_rem(38);
        height: px_to_rem(38);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-evenly;
    }

    .tool_icon {
        width: 75%;
        height: 75%;
    }
}

[data-is-mobile-view="true"] {
    .form {
        height: px_to_rem(54);
    }

    .input {
        font-size: px_to_rem(16);
        padding-left: px_to_rem(17);
        padding-right: px_to_rem(38);
        
    }

    .tools {
        padding: 0 px_to_rem(10);
        width: px_to_rem(38);
        left: unset;
        right: 0;
    }

    .tool {
        width: px_to_rem(18);
        height: px_to_rem(18);
        color: #6A6A6A;
    }

    .tool_icon {
        width: px_to_rem(18);
        height: px_to_rem(18);
    }
}


@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);
    [data-direction='#{$direction}'] {
        .form {
            direction: $dir;
        }
        .tools {
            top: 0;
            bottom: 0;
            #{$left}: unset;
            #{$right}: 0;
        }
        .input {
            padding-#{$right}: px_to_rem(65);
            padding-#{$left}: px_to_rem(25);
        }
        &[data-is-mobile-view="true"] {
            .input {
                padding-#{$right}: px_to_rem(40);
            }
        }
    }
}