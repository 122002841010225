@import '../../functions';

.personal_information {
    @include item_box_shadow();
    border-radius: px_to_rem(20);
    background: #ffffff;
    padding: px_to_rem(50) px_to_rem(55);
    width: px_to_rem(766);
    max-width: 100%;
    margin: 0 auto;
}

.error {
    color: #ff3333;
    font-size: px_to_rem(14);
    font-weight: 400;
    min-height: px_to_rem(16);
    line-height: px_to_rem(16);
    padding: 0 px_to_rem(12);
}

.ltr_data {
    direction: ltr;
    display: inline-block;
}

.form {
    column-gap: px_to_rem(32);
    row-gap: px_to_rem(32);
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.buttons {
    margin-top: px_to_rem(32);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.button {
    width: px_to_rem(313);
    height: px_to_rem(71);
    font-size: px_to_rem(22);
    max-width: 100%;

    &:first-of-type {
        margin-bottom: px_to_rem(14);
    }
}

[data-is-mobile-view="true"] {
    .personal_information {
        box-shadow: none;
        border: none;
        border-radius: 0;
        padding: 0;
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
    }

    .form {
        display: grid;
        grid-template-columns: 1fr;
    }

    .button {
        height: px_to_rem(45);
        font-size: px_to_rem(16);
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        
    }
}