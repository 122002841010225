@import '../../functions';

.footer {
    display: flex;
    height: px_to_rem(280);
    overflow: hidden;
    background: #DFF1F7;
    flex: none;
    color: #164360;
    font-size: px_to_rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    position: sticky; 
    bottom: 0;

    a {
        white-space: nowrap;
    }
}

@media (max-height: 470px) {
    [data-is-mobile="true"] {
        .footer {
            display: none !important;
        }
    }
}

.footer_section {
    flex: none;
    position: relative;
}

.footer_section:nth-child(1), .footer_section:nth-child(3) {
    width: px_to_rem(193);
    padding: 0 px_to_rem(45);
}

.footer_section:nth-child(1)::after {
    content: '';
    display: block;
    width: 1px;
    height: px_to_rem(148);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background: currentColor;
}

.footer_section:nth-child(3) {
    direction: rtl;
}

.links {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    justify-items: start;
    justify-content: space-evenly;
    row-gap: px_to_rem(16);
    column-gap: clamp(px_to_rem(16), 6.62vw, px_to_rem(85));
    padding: 0 px_to_rem(10);

    & > div {
        display: contents;
    }
}

.link span { 
    text-decoration-line: underline;
}

.logo {
    stroke: unset;
    fill: unset;
    width: px_to_rem(114);
    height: px_to_rem(28);
    margin-bottom: px_to_rem(13);
}

.logo_en {
    width: px_to_rem(190);
    height: px_to_rem(22);
}

.credit {
    font-size: px_to_rem(14);
    font-weight: 500;

    a {
        text-decoration-line: underline;
    }
}

.socials {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    column-gap: px_to_rem(17);

    .social {
        display: flex;
        background: #ffffff;
        border-radius: 50%;
    }

    .social_icon {
        width: px_to_rem(40);
        height: px_to_rem(40);
    }
}

.links {
    grid-template-columns: auto auto auto auto auto auto;
}

@media (max-width: 1625px) {
    .links {
        column-gap: px_to_rem(30);
    }
}

@media (max-width: 1365px) {
    .footer_section:nth-child(2) {
        flex: 1;
    }
    .links {
        grid-template-columns: auto auto auto auto;
    }
}

@media (max-width: 1050px) {
    .footer_section:nth-child(1), 
    .footer_section:nth-child(3) {
        width: auto;
        padding: 0 px_to_rem(16);
    }
}

@media (max-width: 870px) {
    .links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
}

[lang="en"] {
    @media (max-width: 1160px) {
        .links {
            grid-template-columns: auto auto auto;
        }
    }
}

[data-is-mobile-view="true"] {
    .footer {
        height: auto;
        padding: px_to_rem(15) px_to_rem(20);
        font-size: px_to_rem(12);
        text-align: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: stretch;
        row-gap: px_to_rem(20);
    }

    .links {
        row-gap: px_to_rem(12);
        column-gap: px_to_rem(16);
        padding: 0;
        grid-template-columns: auto auto auto auto;
        display: grid;

        @media (max-width: 450px) {
            display: flex;
            flex-direction: column;
            align-items: stretch;
        
            & > div {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    .logo {
        width: px_to_rem(60);
        height: px_to_rem(32);
        margin-bottom: 0;
    }
    
    .credit {
        font-size: px_to_rem(10);
    
        a {
            text-decoration-line: underline;
        }
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        .footer_section:nth-child(1) {
            text-align: #{$right};
        }

        .footer_section:nth-child(1)::after {
            #{$left}: 0;
        }

        .footer_section:nth-child(3) {
            text-align: #{$left};
        }
    }
}