@import '../../functions';

.item { 
    display: inline-block;
    white-space: nowrap;
    position: relative;
    font-weight: 400;
    cursor: pointer;

    span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
    }

    &::before {
        content: attr(data-text);
        font-weight: 700;
        opacity: 0;
        pointer-events: none;
        user-select: none;
    }

    &:hover, &.item_selected {
        font-weight: 700;
    }
}

.item_alert {
    font-size: inherit;
    border: none;
    margin: 0;
    padding: 0;
    font-family: inherit;
    background: transparent;
    color: inherit;
}

[data-is-mobile-view="true"] {
    .item.item_selected {
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: px_to_rem(-2);
            left: 0;
            right: 0;
            height: px_to_rem(2);
            background: currentColor;
        }
    }
}