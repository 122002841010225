@import '../../functions';

.containerTranscription {
    display: flex;
    max-width: 100%;
    width: 53rem;
    margin: 0 auto;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    height: 32rem;
}

.microphone {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 50%;
    transition: border-color 0.3s ease;
    background-color: #004165;
    padding: 0.3rem;
    aspect-ratio: 1/1;
    margin-top: 3.5rem;
}

.icon {
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    z-index: 2;
}

.circle {
    position: absolute;
    width: 40px;
    height: 40px;
    border: 2px solid #004165;
    border-radius: 50%;
    opacity: 0;
    animation: none;
}

.microphone:not(.active) {
    border-color: #004165;
}

.microphone.active {
    border-color: transparent;
}

.firstCircle {
    animation-delay: 0s;
}

.secondCircle {
    animation-delay: 0.4s;
}

.microphone.active .circle {
    animation: pulse 1.5s infinite ease-out;
    opacity: 1;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

.microphone.active .circle {
    animation: pulse 1.5s infinite;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 100%;
    margin-top: 0.5rem;
}

.speakBox {
    padding: 16px;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 12px 12px 12px 0px;
    border: 1px solid rgba(20, 92, 153, 0.10);
    background: var(--White, #FFF);
    display: inline-block;
    max-width: fit-content;
    border: 1px solid #ccc;
    word-wrap: break-word;

    .textUnderBox {
        direction: ltr;
    }
}


.translationBox {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 12px 12px 0px 12px;
    background: var(--White, #FFF);
    color: var(--Text, #08263F);
    text-align: right;
    font-style: normal;
    line-height: normal;
    opacity: 0.6;
    height: 30rem;
    height: 30rem;
    width: 100%;
    overflow-y: auto;
    position: relative;
    scroll-behavior: smooth;
    scrollbar-width: none;
}


.textUnderBox {
    margin-top: 0.3rem;
    color: var(--Text, #08263F);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.4;
}

.wrafBoxLeft {
    margin-left: 0;
    margin-right: auto;
    text-align: left;
    width: 60%;

    .box {
        direction: ltr;
    }
}

.wrafBoxRight {
    width: 60%;

    .speakBox {
        border-radius: 12px 12px 0px 12px;
    }
}

.download {
    display: flex;
    flex-direction: row;
    color: var(--new-blue, #164360);
}

.buttonDown {
    all: unset;
    text-decoration: underline;
    cursor: pointer;
    color: var(--new-blue, #164360);
    margin-right: 0.3rem;

}

button:hover {
    text-decoration: none;
}
.currentTranscription{
    width:450px;
    height:50px;
    margin-bottom: 0;
    overflow-y: auto;
    scrollbar-width: none;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        display: none;
    }
}
.empty{
    position: relative;
    height: 1px;
    margin-top: 10px;
    width: 100%;
}

.translating{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}