@import '../../functions';

.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.container_signup {
    justify-content: flex-start;
}

.subscriptions {
    column-gap: px_to_rem(24);
    row-gap: px_to_rem(24);
    width: px_to_rem(1420);
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 1295px) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: center;
    }
}

.subscription {
    @include item_box_shadow();
    background: #ffffff;
    position: relative;
    width: px_to_rem(400);
    padding: px_to_rem(51) px_to_rem(16);
    text-align: center;
    height: px_to_rem(562);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
}

.subscription_main {
    height: px_to_rem(593);
}

.subscription_label {
    position: absolute;
    top: px_to_rem(-3);
    width: px_to_rem(206);
    height: px_to_rem(130);
    mix-blend-mode: darken;
    pointer-events: none;
    overflow: hidden;
    z-index: 1;

    &::before {
        content: attr(data-label);
        width: px_to_rem(246);
        height: px_to_rem(43);
        box-sizing: border-box;
        background: #FCB352;
        color: #ffffff;
        --box-shadow: 0 #{px_to_rem(1)} #{px_to_rem(2)} 0 rgba(0, 0, 0, 0.01), 
                0 #{px_to_rem(3)} #{px_to_rem(3)} 0 rgba(0, 0, 0, 0.02), 
                0 #{px_to_rem(7)} #{px_to_rem(4)} 0 rgba(0, 0, 0, 0.03);
        box-shadow: var(--box-shadow);
        font-size: px_to_rem(27);
        font-weight: 800;
        line-height: 0.87;
        position: absolute;
        top: px_to_rem(-43);
    }
}

.title {
    font-size: px_to_rem(96);
    font-weight: 800;
    line-height: 0.87;
    text-align: center;
}

.subscription_label + .title {
    margin-top: px_to_rem(31);
}

.subscription_content {
    color: #000000;
    width: px_to_rem(250);
}

.subscription_price {
    font-size: px_to_rem(32);
    font-weight: 700;

    span {
        font-size: px_to_rem(20);
    }
}

.subscription_text {
    font-size: px_to_rem(24);
    font-weight: 400;
}

.subscription_buttons {

}

.subscription_button {
    width: px_to_rem(313);
    height: px_to_rem(71);
    font-size: px_to_rem(24);
}

.sign_up {
    .title {
        line-height: 1;
        margin-bottom: px_to_rem(38);
    }
}

.container_corporate {
    justify-content: space-between;

    & > div:first-child {
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
    }
}

.hubspo_form {
    width: px_to_rem(766);
    max-width: 100%;
    padding: px_to_rem(50);
    box-sizing: border-box;
    margin: 0 auto;
}

.subscription_org_submitted_title {
    color: #000000;
    text-align: center;
    font-size: px_to_rem(32);
    font-weight: 700;
    margin-bottom: px_to_rem(20);
    margin-top: px_to_rem(80);
}

.subscription_org_submitted_text {
    color: #000000;
    text-align: center;
    font-size: px_to_rem(32);
    font-weight: 400;
}

[lang="en"] {
    .subscriptions {
        width: px_to_rem(1639);

        @media (max-width: 1622px) {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            align-items: center;
        }
    }
    
    .subscription {
        width: px_to_rem(509);
        height: px_to_rem(596);

        &:nth-child(1) {
            .title {
                font-size: px_to_rem(64);
            }
        }
    }

    .subscription_main {
        height: px_to_rem(672);
    }

    .subscription_content {
        width: px_to_rem(348);
    }

    .title {
        line-height: 1.1;
    }

    [data-is-mobile-view="true"] {
        .subscription {
            &:nth-child(1) {
                .title {
                    font-size: px_to_rem(36);
                    line-height: 1.1;
                }
            }
        }

        .subscription_content {
            width: 80%;
        }
    }
}

[data-is-mobile-view="false"] {
    .hubspo_form {
        @include item_box_shadow();
        background: #ffffff;
    }
}

[data-is-mobile-view="true"] {
    .container_signup {
        justify-content: space-between;
    }

    .subscription {
        width: 100%;
        padding: px_to_rem(32) px_to_rem(10);
        height: auto;
        row-gap: px_to_rem(24);

        & span:first-child::before {
            width: px_to_rem(24);
            height: px_to_rem(24);
            top: -0.2em;
        }
    }

    .subscription_label {
        display: none;
    }

    .subscription_label + .title {
        margin-top: 0;
    }

    .title {
        font-size: px_to_rem(36);
        font-weight: 700;
        line-height: normal;
    }

    .subscription_content {
        width: px_to_rem(80%);
    }

    .subscription_price {
        font-size: px_to_rem(18);
        font-weight: 400;
    
        span {
            font-size: px_to_rem(16);
        }
    }
    
    .subscription_text {
        font-size: px_to_rem(18);
    }

    .subscription_button {
        width: px_to_rem(170);
        height: px_to_rem(43);
        font-size: px_to_rem(16);
    }

    .sign_up {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
    }

    .container_corporate {
        & > div:first-child {
            justify-content: center;
        }
    }

    .hubspo_form {
        width: 100%;
        padding: px_to_rem(0);
    }

    .subscription_org_submitted_title {
        font-size: px_to_rem(22);
        margin-top: px_to_rem(-80);
    }
    
    .subscription_org_submitted_text {
        font-size: px_to_rem(18);
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);
  
    [data-direction='#{$direction}'] {
        .subscription_label {
            #{$right}: px_to_rem(-3);
        
            &::before {
                padding: if-rtl(px_to_rem(12) px_to_rem(26) px_to_rem(9) px_to_rem(47), px_to_rem(12) px_to_rem(47) px_to_rem(9) px_to_rem(26));
                transform: if-rtl(rotate(31.319deg), rotate(-31.319deg));
                transform-origin: if-rtl(left bottom, right bottom);
                #{$left}: 0;
            }
        }

        &[data-is-mobile-view="true"] {
            .subscription {
                & span:first-child::before {
                    #{$left}: 0;
                    margin: 0 px_to_rem(-12);
                }
            }
        }
    }
}