@import '../../functions';

.container {
    position: relative;
}

.container_login {
    min-height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.block {
    position: relative;
    flex: none;
}

.block_title {
    font-size: px_to_rem(32);
    font-weight: 700;
}

.block_log_in {
    display: flex;
    min-height: calc(100svh - px_to_rem(87) - px_to_rem(20));
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;

    &>div:nth-child(1) {
        width: px_to_rem(1620);
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex: 1;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;

        &>div {
            color: #000000;
            font-size: px_to_rem(32);
            font-weight: 500;
            font-style: normal;
            line-height: normal;
            margin-bottom: px_to_rem(60);
        }
    }
}

.logo {
    width: px_to_rem(294);
    height: px_to_rem(96);
    display: block;
    stroke: none;
    stroke-width: 0;
}

.logo_en {
    width: px_to_rem(451);
    height: px_to_rem(94);
}

.opening_text {
    margin-top: px_to_rem(32);
    margin-bottom: px_to_rem(45);
}

.buttons {
    display: flex;
    position: relative;
    width: px_to_rem(313);
    row-gap: px_to_rem(20);
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.sign_up_button {
    width: px_to_rem(313);
    height: px_to_rem(71);
    font-size: px_to_rem(32);
    white-space: nowrap;
}

.log_in_button {
    font-size: px_to_rem(28);
}

.topics {
    color: #164360;
    font-size: px_to_rem(28);
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    margin-bottom: px_to_rem(40);
    column-gap: px_to_rem(80);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    span {
        white-space: nowrap;
        position: relative;
        display: block;

        &::after {
            content: '';
            display: block;
            width: px_to_rem(4);
            height: px_to_rem(12);
            border-radius: px_to_rem(2);
            background: #D68B28;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
        }

        &:last-child::after {
            display: none;
        }
    }
}

.block_about {
    color: #000000;
    font-style: normal;
    line-height: normal;
    width: px_to_rem(1620);
    max-width: 100%;
    margin: 0 auto;
    margin-top: px_to_rem(202);
    margin-bottom: px_to_rem(183);
    column-gap: px_to_rem(130);
    overflow: visible;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 1220px) {
        column-gap: px_to_rem(32);
    }
}

.about_content {
    flex: none;
    padding-top: px_to_rem(79);
    row-gap: px_to_rem(32);
    width: px_to_rem(773);
    max-width: 50vw;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
}

.about_text {
    font-size: px_to_rem(22);
    font-weight: 400;
}

.about_image {
    flex: none;
    position: relative;
    height: px_to_rem(742);
    width: px_to_rem(774);
    background-image: url('./images/preview_pc.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.block_customers {
    text-align: center;
    margin: 0 px_to_rem(-20);
    margin-bottom: px_to_rem(332);

    .block_title {
        margin-bottom: px_to_rem(84);
    }
}

.customers {
    row-gap: px_to_rem(45);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
    position: relative;
}
.wrap_block{
    &:nth-child(1) .block_img {
        height: px_to_rem(177);
        
    }

    &:nth-child(2) .block_img {
        height: px_to_rem(184);

    }

    &:nth-child(3) .block_img {

        height: px_to_rem(214);

    }
}


[data-is-mobile-view="true"] {
    .buttons {
        max-width: 100%;
        row-gap: px_to_rem(3);
        margin: 0 auto;
        margin-top: px_to_rem(30);
        margin-top: max(calc(px_to_rem(99) - (var(--scroll-top) * (1 - var(--scrol-anchor)) * 1px)), px_to_rem(30));
    }

    .scroll_anchor {
        margin-top: min(calc((var(--scroll-top) * (1 - var(--scrol-anchor)) * 1px) - px_to_rem(69)), 0px);
        height: max(px_to_rem(69), 0px);
    }

    .sign_up_button {
        max-width: 100%;
        height: px_to_rem(56);
        font-size: px_to_rem(18);
    }

    .log_in_button {
        font-size: px_to_rem(18);
    }

    .topics {
        margin: 0 px_to_rem(-16);
        row-gap: px_to_rem(29);

        span {
            &::after {
                width: px_to_rem(2);
                height: px_to_rem(10);
            }
        }

        &:nth-child(1) {
            margin-top: px_to_rem(50);
            margin-bottom: px_to_rem(29);
        }

        &:nth-child(2) {
            margin-bottom: px_to_rem(82);
        }
    }

    .block_about {
        color: #164360;
        margin-top: 0;
        margin-bottom: px_to_rem(70);
        display: block;

        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }

    .about_content {
        padding-top: 0;
        width: 100%;
        max-width: unset;
        display: block;

        .block_title {
            margin-bottom: px_to_rem(32);
        }
    }

    .about_text {
        font-size: px_to_rem(14);
        text-align: justify;
    }

    .about_image {
        height: px_to_rem(232);
        width: px_to_rem(170);
        background-image: url('./images/preview_mobile.png');
        shape-outside: url('./images/preview_mobile.png');
        background-size: calc(100% - px_to_rem(20)) auto;
    }

    .block_customers {
        margin-bottom: px_to_rem(24);

        .block_title {
            margin-bottom: px_to_rem(25);
        }
    }

    .customers {
        row-gap: px_to_rem(15);

        .wrap_block {
            &:nth-child(1) {
                height: px_to_rem(56);
            }

            &:nth-child(2) {
                height: px_to_rem(58);
            }

            &:nth-child(3) {
                height: px_to_rem(68);
            }
        }
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        .topics {
            span {
                &::after {
                    #{$left}: px_to_rem(-42);
                }
            }
        }

        .about_image {
            background-position: top #{$right};
            #{$right}: 0px;
            #{$right}: max(calc(50svh - px_to_rem(87) - (var(--scroll-top) * 0.5px)), 0px);
        }



        &[data-is-mobile-view="true"] {
            .topics {
                font-size: if-rtl(px_to_rem(16), px_to_rem(14));
                column-gap: if-rtl(px_to_rem(46), px_to_rem(12));

                span {
                    &::after {
                        #{$left}: if-rtl(px_to_rem(-24), px_to_rem(-7));
                    }
                }
            }

            .about_image {
                background-position: center center;
                #{$right}: 0;
                float: #{$left};
                margin-#{$left}: px_to_rem(-20);
            }
        }
    }
}

