@import '../../functions';

.slider {
    --items-position: 0;
    --items-gap: #{px_to_rem(40)};
    --items-in-view: 3;
    --items-width: calc((100% - ((var(--items-in-view) - 1) * var(--items-gap))) / var(--items-in-view));
    --items-box-shadow-padding: #{px_to_rem(10)} 0;

    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    column-gap: px_to_rem(49);
}

.slider_dynamic {
    position: relative;
    margin: 0 px_to_rem(-104);
    width: calc(100% + px_to_rem(104) + px_to_rem(104));
    box-sizing: border-box;
}

@media (max-width: 1270px) {
    .slider_dynamic {
        width: 100%;
        margin: 0;
    }
}

.controller {
    @include item_box_shadow;
    position: relative;
    flex: none;
    width: px_to_rem(55);
    height: px_to_rem(55);
    background: #ffffff;
    color: #B8DEE9;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    box-sizing: content-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.controller_icon {
    width: px_to_rem(12);
    height: px_to_rem(18);
}

.container {
    flex: 1;
    position: relative;
    overflow: hidden;
    padding: var(--items-box-shadow-padding);
    cursor: e-resize;
}

.container_static {
    cursor: default;
}

.items {
    position: relative;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow: visible;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    column-gap: var(--items-gap);
}

.item {
    flex: none;
    position: relative;
    width: var(--items-width);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;

    &, & * {
        user-select: none;
        -webkit-user-drag: none;
    }
}

[data-is_scrolling] .item::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0%;
    transition: 0s height 0.05s linear;
}

[data-is_scrolling="true"] .item::after {
    height: 100%;
    transition: 0s height 0s linear;
}

[data-is-mobile-view="true"] {
    .slider, 
    .slider_dynamic {
        position: relative;
        margin: 0 px_to_rem(-20);
        width: 100svw;
        box-sizing: border-box;
    }

    .controller {
        display: none;
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        .items {
            #{$right}: var(--items-position);
            will-change: #{$right};
        }

        [data-is_animated="true"] {
            transition: #{$right} 0.5s;
        }
    }
}