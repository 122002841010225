@import '../../functions';

.tabs_wrapper {
  --tabs-position: 0;
  position: relative;
  overflow: hidden;
  display: block;
  box-sizing: border-box;
    
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.tabs {
  flex: none;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.tab_wrap {
  padding: 0;
  margin: 0;
}

[data-is_scrolling] .tabs::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0%;
  transition: 0s height 0.05s linear;
}

[data-is_scrolling="true"] .tabs::after {
  height: 100%;
  transition: 0s height 0s linear;
}

.tab_wrapp_selected {
  @include item_box_shadow;
  border-bottom: none;
  background-color: white;
  border-radius: 8px 8px 0 0;
  z-index: 2;
  padding: auto;

}


.tab {
  position: relative;
  color: #000;
  font-size: px_to_rem(20);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 0 0.2rem 0;
  margin: 0.5rem 1rem 0.7rem 1rem;
  background: white;
  border: none;
  cursor: pointer;

  &.tab_selected,
  &:hover {
    font-weight: 700;
  }



  &::before {
    content: attr(data-text);
    font-weight: 700;
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: px_to_rem(-11);
    left: px_to_rem(-10);
    right: px_to_rem(-10);
    height: px_to_rem(4);
    background-color: transparent;
    transition: background-color 0.25s ease-in-out;
    will-change: background-color;
    pointer-events: none;

  }

  &.tab_selected {
    border-bottom: 0.13rem solid #164360;
  }


  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
}

[data-is-mobile-view="true"] {
  .tabs_wrapper {
    overflow: auto;
  }

  .tabs {
    flex: none;
    padding: 0 px-to-rem(13) px-to-rem(8) px-to-rem(13);
    margin: 0 px-to-rem(-20);
    column-gap: px-to-rem(2);
    display: inline-flex;
    width: auto;
  }

  .tab_wrapp {
    padding: 0;
    margin: 0;
  }

  .tab {
    font-size: px-to-rem(14);
    font-weight: 400;
    padding: 0 px-to-rem(7);
    margin: 0;
    margin-bottom: 0.6rem;

    &.tab_selected,
    &:hover,
    &::before {
      font-weight: 700;
    }

    &.tab_selected {
      border-bottom: none;
    }

    &::after {
      bottom: px_to_rem(-7);
      left: px_to_rem(2);
      right: px_to_rem(2);
      height: px_to_rem(3);
    }

    &.tab_selected::after {
      background-color: #B8DEE9;
    }


  }
}

@each $direction in (ltr, rtl) {
  $dir: $direction;
  $dirOpposite: if-rtl(ltr, rtl);
  $left: if-rtl(left, right);
  $right: if-rtl(right, left);

  [data-direction='#{$direction}'] {
    .tabs {
      margin-#{$right}: var(--tabs-position);
    }

    &[data-is-mobile-view="true"] {
      .tabs {
        margin-#{$right}: 0;
      }
    }
  }
}