@import '../../functions';

.container {
    font-size: 18px;
    color: #08263F;
    width: 600px;
}

.language_btn {
    width: 50px;
    flex: none !important;
}

.pressed_btn {
    background: #cccccc !important;
}