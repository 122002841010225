@import '../../../functions';

.contaner {
    display: inline-block;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -2px);
    display: grid;
    width: max-content;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.25s ease-in-out;
    will-change: grid-template-rows;
}

.items {
    padding: px_to_rem(0) px_to_rem(12);
    transition: padding 0.25s ease-in-out;
    will-change: padding;
    row-gap: px_to_rem(22);
    background: #DFF1F7;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;

    .item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;

        span {
            display: block;
        }
    }
}

.contaner:hover, .contaner:has(.item:focus) {
    .dropdown {
        grid-template-rows: 1fr;
    }

    .items {
        padding: px_to_rem(24) px_to_rem(12) px_to_rem(12) px_to_rem(12);
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        .item {
            text-align: $right;
        }
    }
}