@import '../../functions';

.container {
    row-gap: px_to_rem(22);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 auto;
    margin-top: px_to_rem(22);
    width: fit-content;
    min-width: px_to_rem(320);
    max-width: 100%;
}

.button {
    @include item_box_shadow();
    border-width: px_to_rem(2);
    margin: 0;
    padding: px_to_rem(20) px_to_rem(12);
    display: block;
    outline: none;
    box-sizing: border-box;
    background: #ffffff;
    color: #164360;
    font-size: px_to_rem(22);
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

}

.cancel_subscription_button {
    color: #E91919;
}

[data-is-mobile-view="true"] {
    .container {
        width: 100%;
        flex: 1;
    }

    .button {
        padding: px_to_rem(10) px_to_rem(12);
        font-size: px_to_rem(16);
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);
  
    [data-direction='#{$direction}'] {
        
    }
}