@import '../../functions';

.title {
    --text-stroke-color: #164360;
    color: #ffffff;
    text-shadow: -1px -1px 0 var(--text-stroke-color),
                -1px 0px 0 var(--text-stroke-color),
                -1px 1px 0 var(--text-stroke-color),
                0px -1px 0 var(--text-stroke-color),
                0px 0px 0 var(--text-stroke-color),
                0px 1px 0 var(--text-stroke-color),
                1px -1px 0 var(--text-stroke-color),
                1px 0px 0 var(--text-stroke-color),
                1px 1px 0 var(--text-stroke-color);
    font-family: 'Assistant', sans-serif;
    font-size: px_to_rem(96);
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    position: relative;
}

.prefix span:first-child::before {
    content: "";
    display: inline-block;
    position: relative;
    top: -0.4em;
    border-radius: 50%;
    width: px_to_rem(46);
    height: px_to_rem(46);
    background-color: #B8DEE9;
    margin: 0 px_to_rem(-23);
    mix-blend-mode: darken;
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        .prefix span:first-child::before {
            #{$left}: -0.01em;
        }
    }
}