@import '../../../functions';

.hubspo {
    width: px_to_rem(1041);
    max-width: 100%;
    height: auto;
    min-height: px_to_rem(200);
    margin: px_to_rem(52) auto 0 auto;
    display: block;
    border: none;
    padding: 0;
}

[data-is-mobile-view="true"] {
    .hubspo {
        margin: 0 auto;
    }
}