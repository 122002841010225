@import '../../functions';

.explain {
    display: contents;
}

.link, 
.BLUE {
    color: var(--link-color);
}
  
.color1 {
    color: #808080;
}
  
.color2 {
    color: #AA0000;
}

.UNDERLINE {
    text-decoration: underline !important;
    text-underline-offset: px_to_rem(6);
}

.BOLD {
    font-weight: bold !important;
}