@import '../../functions';

.container {
  width: px_to_rem(1150);
  max-width: 100%;
  margin: px_to_rem(40) auto 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 0;
}

.sticky_search_input {
  width: 100%;
  padding: px_to_rem(16) 0;
  margin: px_to_rem(-16) 0 0 0;
  box-shadow: px_to_rem(-16) 0 0 0 #ffffff, px_to_rem(16) 0 0 0 #ffffff;
  background: #ffffff;
  position: sticky;
  top: px_to_rem(85);
}

.header_search_input {
  margin-top: px_to_rem(27);
}

.search_but {
  margin-top: px_to_rem(130);
}

.number_of_search_results {
  color: #164360;
  font-size: px_to_rem(14);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  margin-top: px_to_rem(10);
}

.search_results_words {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  column-gap: px_to_rem(27);
  row-gap: px_to_rem(10);
}

.search_results_word {
  @include item_box_shadow;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-rows: subgrid;
  grid-row-end: span 3;
  row-gap: px_to_rem(10);
  padding: px_to_rem(8) px_to_rem(16);
  color: #000000;
  font-size: px_to_rem(18);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
  direction: rtl;
  user-select: text;

  &.search_results_word_en {
    text-align: left;
    direction: ltr;

    .search_results_word_translation {
      text-align: right;
      direction: rtl;
    }
  }
}

.search_results_word_title {
  font-size: px_to_rem(24);
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: px_to_rem(10);
  .search_results_word_diacritics {
    font-size: px_to_rem(16);
    font-weight: 600;
  }
}

.search_results_word_partOfSpeech {
  font-size: px_to_rem(16);
  font-weight: 400;
}



.search_results_word_explain {
  a {
    text-decoration: none;
  }
}

.search_results_word_translation {
  text-align: left;
  direction: ltr;
}

.search_results_correction {
  flex: 1;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  color: #164360;
  font-style: normal;
  line-height: normal;
}

.search_results_correction_icon {
  margin-top: px_to_rem(34);
  width: px_to_rem(75);
  height: px_to_rem(75);
  display: inline-block;
  opacity: 0.2;
  position: relative;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.search_results_correction_title {
  margin-top: px_to_rem(12);
  font-size: px_to_rem(32);
  font-weight: 700;
}

.search_results_correction_sub_title {
  margin-top: px_to_rem(56);
  font-size: px_to_rem(28);
  font-weight: 500;
}

.search_results_correction_list {
  margin: px_to_rem(32) auto 0 auto;
  width: px_to_rem(750);
  max-width: 100%;
  column-gap: px_to_rem(40);
  row-gap: px_to_rem(10);
  font-size: px_to_rem(24);
  font-weight: 400;
  text-decoration-line: underline;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.search_results_correction_contact_us {
  margin-top: px_to_rem(20);
  font-size: px_to_rem(18);
  margin-top: px_to_rem(18);
  display: inline-block;
  font-weight: 500;

  a {
    text-decoration-line: underline;
  }
}

.full_analyze_header {
  flex: none;
  direction: rtl;
  width: 100%;
  column-gap: px_to_rem(16);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;

  .search_results_word {
    flex: 1;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    row-gap: px_to_rem(2);
    font-size: px_to_rem(24);
  }

  .search_results_word_title>h1 {
    font-size: px_to_rem(36);
    column-gap: px_to_rem(20);
    margin: 0;
    font-weight: 700;

  }

  .search_results_word_partOfSpeech {
    font-size: px_to_rem(24);
  }

  .search_results_word_diacritics {
    font-size: px_to_rem(24);
  }
}

.full_analyze_search_input {
  width: px_to_rem(387);
  height: px_to_rem(61);
  margin-top: px_to_rem(16);
  flex: none;
}

.word_list_and_extension {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
  direction: rtl;
}

.others_words_list {
  width: 100%;
  margin-top: px_to_rem(25);
  border-top: 2px solid #DFF1F7;
  padding-top: 0.8rem;
}

.search_and_words_list {
  width: px_to_rem(410);

}

.search_results_selected_word {
  margin: 1rem;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-rows: subgrid;
  grid-row-end: span 3;
  padding: px_to_rem(8) px_to_rem(16);
  color: #000000;
  font-size: px_to_rem(18);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
  direction: rtl;
  user-select: text;

  &.search_results_word_en {
    text-align: left;
    direction: ltr;

    .search_results_word_translation {
      text-align: right;
      direction: rtl;
    }
  }

  &.search_results_word_num {
    text-align: right;
    direction: rtl;

    .search_results_word_translation {
      text-align: left;
      direction: ltr;
    }
  }
}

.search_results_word_title>h1 {
  font-size: 1.4rem;
  column-gap: px_to_rem(20);
  margin: 0;
  font-weight: 700;

}

.search_results_word_explain {
  font-size: 1rem;
  font-weight: 400;
  margin: 0.8rem 0;
}

.search_results_word_translation {
  font-size: 1.1rem;
  font-weight: 400;
}



.search_input_aside {
  width: 100%;
  height: px_to_rem(61);
}

.full_analyze_data {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
}

.full_analyze_data_content {
  flex: 1;
  min-height: px_to_rem(300);
  box-sizing: border-box;
  @include item_box_shadow;
  position: relative;
  padding: px_to_rem(30);
  overflow-x: hidden;
  overflow-y: auto;
  user-select: text;
  direction: rtl;
  text-align: right;
  font-style: normal;
  font-size: px_to_rem(18);
  font-weight: 400;
  color: #000000;
  margin-top: -0.17rem;

  h2 {
    font-size: px_to_rem(18);
    font-weight: 700;
    margin: 0;
  }

  ul {
    list-style-type: disc;
    padding: 0;
  }

  ol {
    margin: 0;
    padding-right: 1.5em;
  }

  ol>li {
    margin-bottom: px_to_rem(18);

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
  }

  .full_analyze_explanation_example {
    color: #164360;
    padding: 0 px_to_rem(20);
    margin: px_to_rem(5) 0;
  }

  .full_analyze_explanation {
    margin-bottom: px_to_rem(10);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .full_analyze_explanation_group {
    margin-bottom: px_to_rem(30);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .full_analyze_data_list {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
    justify-items: start;
    column-gap: px_to_rem(16);
    row-gap: px_to_rem(10);

    @media (max-width: 650px) {
      grid-template-columns: auto auto;
    }

    @media (max-width: 450px) {
      grid-template-columns: auto;
    }
  }

  .full_analyze_analysis_grammar {
    b {
      font-size: px_to_rem(20);
    }

    h2 {
      font-size: px_to_rem(20);
      font-weight: 400;
      margin: 0;
    }
  }

  .full_analyze_analysis_table {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: px_to_rem(40);
    column-gap: px_to_rem(195);

    table {
      border-collapse: collapse;
      margin-top: px_to_rem(20);
    }

    td {
      border: 1px solid #000000;
      padding: px_to_rem(10) px_to_rem(20);
      min-width: px_to_rem(120);
      box-sizing: border-box;
    }

  }
}

[data-is-desktop="true"] {
  .search_results_word_explain {
    a:hover {
      text-decoration: underline;
      text-underline-offset: px_to_rem(6);
    }
  }

  .full_analyze_data_content {
    a:hover {
      text-decoration: underline;
      text-underline-offset: px_to_rem(6);
    }
  }
}

.full_analyze_other_words {
  flex: none;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 0 1rem;
  direction: rtl;


  .full_analyze_other_words_title {
    margin-bottom: px_to_rem(20);
    color: #164360;
    font-size: px_to_rem(17);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;


  }

  .search_results_word {
    grid-template-rows: auto 1fr;
    font-size: px_to_rem(16);
    padding: px_to_rem(16);
    width: 100%;
  }

  .search_results_word_title {
    font-size: px_to_rem(22);
  }
}
.full_analyze_other_words_overflow {
  height: 20rem;
  min-height: 48vh;
  overflow-y: auto;
  direction: ltr;
}

.full_analyze_other_words_overflow::-webkit-scrollbar {
  width: 0.2rem;
}

.full_analyze_other_words_overflow::-webkit-scrollbar-track {
  background: none;
}

.full_analyze_other_words_overflow::-webkit-scrollbar-thumb {
  background: rgba(14, 64, 107, 0.20);
}


[data-is-mobile-view="false"] {
  :global(#page_content) {
    &:has(.container_search_results) {
      display: contents;

      .container {
        max-width: calc(100% - px_to_rem(40));
        margin: px_to_rem(60) auto px_to_rem(40) auto
      }
    }
  }
}

[data-is-mobile-view="true"] {
  .container {
    justify-content: space-between;

    &.container_search_results_Match {
      margin-top: 0;
      justify-content: flex-start;
    }

    &.container_search_results_Suggestions {
      margin-top: px_to_rem(15);
    }

    &.container_full_analyze {
      margin-top: px_to_rem(10);
    }
  }

  .search_but {
    margin-top: px_to_rem(30);
  }

  .search_results_words {
    grid-template-columns: 1fr;
    row-gap: px_to_rem(20);
    margin-top: px_to_rem(30);
  }

  .search_results_word {
    padding: px_to_rem(10) px_to_rem(12);
    font-size: px_to_rem(14);
  }

  .search_results_word_title>h1 {
    font-size: px_to_rem(16);
    font-weight: 500;
    column-gap: px_to_rem(5);
  }

  .search_results_word_partOfSpeech {
    font-size: px_to_rem(14);
    font-weight: 300;
  }

  .search_results_word_diacritics {
    font-size: px_to_rem(14);
  }

  .search_results_correction_icon {
    margin-top: 0;
    width: px_to_rem(55);
    height: px_to_rem(55);
  }

  .search_results_correction_title {
    margin-top: px_to_rem(5);
    font-size: px_to_rem(18);
  }

  .search_results_correction_sub_title {
    margin-top: px_to_rem(32);
    font-size: px_to_rem(16);
  }

  .search_results_correction_list {
    margin-top: px_to_rem(12);
    font-size: px_to_rem(16);
    row-gap: px_to_rem(12);
    font-weight: 500;
  }

  .search_results_correction_contact_us {
    font-size: px_to_rem(14);
    margin-top: px_to_rem(14);
  }

  .full_analyze_header {
    .search_results_word {
      padding: 0;
      row-gap: px_to_rem(10);
      font-size: px_to_rem(14);
    }

    .search_results_word_title {
      font-size: px_to_rem(16);
      column-gap: px_to_rem(5);
    }

    .search_results_word_partOfSpeech {
      font-size: px_to_rem(14);
      font-weight: 300;
    }

    .search_results_word_diacritics {
      font-size: px_to_rem(14);
    }
  }

  .full_analyze_data {
    flex: 1;
    margin-top: px_to_rem(20);

  }

  .full_analyze_data_content {
    padding: 0.9rem px_to_rem(14);
    font-size: px_to_rem(14);
 
    

    h2 {
      font-size: px_to_rem(14);
      font-weight: 700;
      margin: 0;
    }

    ol>li {
      margin-bottom: px_to_rem(16);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .full_analyze_analysis_grammar {
      b {
        font-size: px_to_rem(16);
      }

      h2 {
        font-size: px_to_rem(16);
        font-weight: 400;
        margin: 0;
      }
    }

    .full_analyze_analysis_table {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: stretch;

      table {
        width: 100%;

        & tr td:first-child {
          width: px_to_rem(160);
        }
      }
    }
  }

  .full_analyze_other_words {
    margin-top: px_to_rem(30);

    .search_results_word {
      padding: px_to_rem(16);
      text-align: center;
    }

    .search_results_word_title {
      column-gap: px_to_rem(10);
      font-weight: 700;
      justify-content: center;
      margin: 0 auto;
      padding: px_to_rem(8) px_to_rem(3);
      border-bottom: 1px solid #B8DEE9;
      box-sizing: border-box;
    }

    .search_results_word_partOfSpeech {
      font-weight: 400;
    }
  }
  .search_results_selected_word {
    margin: 0;
    grid-template-rows: 0;
  }
}

@each $direction in (ltr, rtl) {
  $dir: $direction;
  $dirOpposite: if-rtl(ltr, rtl);
  $left: if-rtl(left, right);
  $right: if-rtl(right, left);
  [data-direction='#{$direction}'] {
    .number_of_search_results {
      text-align: #{$right};
    }
    .full_analyze_other_words_title {
      text-align: #{$right};
    }
    .full_analyze_other_words {
      direction:#{$dir};
    }
    // .full_analyze_other_words_overflow{
    //   direction:#{$dirOpposite};
    // }
    &[data-is-mobile-view="true"] {
      .full_analyze_other_words {
        direction:#{$dir};
      }
    }
  }
}
