@import '../../../functions';

.container {
    color: #000000;
    font-size: px_to_rem(18);
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    width: px_to_rem(810);
    max-width: 100%;
    margin: 0 auto;
}

[data-is-mobile-view="true"] {
    .container {
        font-size: px_to_rem(16);
    }
}