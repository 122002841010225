@import '../../functions';

.login {
    width: px_to_rem(503);
    height: 100%;
    padding: px_to_rem(55);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: center;
}

.content {
    display: block;
}

.ltr_data {
    direction: ltr;
    display: inline-block;
}

.title {
    color: #164360;
    text-align: center;
    font-size: px_to_rem(28);
    font-weight: 700;
    margin-bottom: px_to_rem(102);
}

.text {
    color: #000000;
    font-size: px_to_rem(16);
    font-weight: 400;
    margin-bottom: px_to_rem(35);
    text-align: center;
}

.fields {
    row-gap: px_to_rem(32);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: px_to_rem(5);
}

.forgot_password_button {
    color: #000000;
    font-size: px_to_rem(14);
    font-weight: 400;
    height: px_to_rem(21);
    margin: 0;
    padding: 0;
}

.buttons {
    row-gap: px_to_rem(14);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: px_to_rem(29);

    & > div {
        width: 100%;
    }
}

.error {
    color: #ff3333;
    font-size: px_to_rem(14);
    font-weight: 400;
    min-height: px_to_rem(16);
    line-height: px_to_rem(16);
    padding: 0 px_to_rem(12);
}

.main_button {
    width: 100%;
    height: px_to_rem(55);
}

.org {
    margin-top: px_to_rem(35);
    padding-top: px_to_rem(35);
    border-top: 1px solid #cccccc;
    row-gap: px_to_rem(20);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;

    .org_button {
        width: 100%;
        height: px_to_rem(55);
        box-sizing: border-box;
        border: 1px solid #164360;
        border-radius: px_to_rem(10);
        cursor: pointer;
        column-gap: px_to_rem(10);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        color: #164360;
        font-size: px_to_rem(18);
        font-weight: 500;
    }

    .edu_button {
        &::before {
            content: '';
            display: block;
            width: px_to_rem(26);
            height: px_to_rem(33);
            background-image: url('./images/edu.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
}

@media (max-height: 800px) { 
    .login {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: space-between;
        overflow: auto;
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;

        & > div:first-child {
            flex: 1;
        }
    }
}

[data-is-mobile-view="true"] {
    .login {
        padding: 0;
        width: 100%;
        min-height: 100%;
        justify-content: flex-start;
        flex: 1;
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;

        & > div:first-child {
            flex: 1;
        }
    }

    .title {
        display: none;
    }
}

@each $direction in (ltr, rtl) {
    $dir: $direction;
    $dirOpposite: if-rtl(ltr, rtl);
    $left: if-rtl(left, right);
    $right: if-rtl(right, left);

    [data-direction='#{$direction}'] {
        .login {
            direction: $direction;
        }

        .forgot_password {
            direction: $dirOpposite;
        }

        .forgot_password_button {
            direction: $direction;
        }
    }
}