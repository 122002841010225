@function px_to_rem($px, $baseFS: 16) {
    $rem: ($px / $baseFS);
    @return #{$rem}rem; 
}

@mixin item_box_shadow() {
  --box-shadow: 0 #{px_to_rem(1)} #{px_to_rem(2)} 0 rgba(0, 0, 0, 0.01), 
                0 #{px_to_rem(3)} #{px_to_rem(3)} 0 rgba(0, 0, 0, 0.02), 
                0 #{px_to_rem(7)} #{px_to_rem(4)} 0 rgba(0, 0, 0, 0.03);
  box-shadow: var(--box-shadow);
  border-radius:px_to_rem(10);
  border: px_to_rem(3) solid #F8F8F8;
  box-sizing: border-box;
}

@mixin home_page_pc_bg($direction) {
  $left: left;
  $home_page_path_bg: url('../../images/home_page_path_bg.svg');
  @if $direction != rtl {
    $left: right;
    $home_page_path_bg: url('../../images/home_page_path_bg_ltr.svg');
  }

  background-color: #ffffff;
  background-image: url('../../images/home_page_text_s_bg.png'), 
                    url('../../images/home_page_text_l_bg.png'), 
                    #{$home_page_path_bg};
  background-position: top calc(px_to_rem(185) - (var(--scroll-top) * 1px)) #{$left} calc(50vw - px_to_rem(562) + (var(--scroll-top) * 0.7px)), 
                        top calc(px_to_rem(416) - (var(--scroll-top) * 1.5px)) #{$left} calc(50vw - px_to_rem(658) - (var(--scroll-top) * 1.2px)), 
                        top 0px #{$left} calc(var(--scroll-top) * 2.5px - 570px);
  background-size: px_to_rem(117) px_to_rem(117), 
                    px_to_rem(427) px_to_rem(427), 
                    auto;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (max-width: 1370px) { 
    background-position: top calc(px_to_rem(185) - (var(--scroll-top) * 1px)) #{$left} calc(px_to_rem(110) + (var(--scroll-top) * 0.7px)), 
                        top calc(px_to_rem(416) - (var(--scroll-top) * 1.5px)) #{$left} calc(px_to_rem(20) - (var(--scroll-top) * 1.2px)), 
                        top 0px #{$left} calc(var(--scroll-top) * 1px - 570px);
  }
}

@mixin home_page_pc_path_bg($direction) {
  $left: left;
  $home_page_path_bg: url('../../images/home_page_path_bg.svg');
  @if $direction != rtl {
    $left: right;
    $home_page_path_bg: url('../../images/home_page_path_bg_ltr.svg');
  }

  background-color: #ffffff;
  background-image: #{$home_page_path_bg};
  background-position: top 0px #{$left} calc(var(--scroll-top) * 2.5px - 570px);
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (max-width: 1370px) { 
    background-position: top 0px #{$left} calc(var(--scroll-top) * 1px - 570px);
  }
}

@mixin mobile {
    @media (max-width: 768px) {
        @content;
    }
}


/*#region direction mode */

$dir: ltr !default; 

@function if-ltr($if, $else: null) {
  @if $dir != rtl {
    @return $if;
  }
  @else {
    @return $else;
  }
}

@function if-rtl($if, $else: null) {
  @return if-ltr($else, $if);
}

$dirOpposite: if-rtl(ltr, rtl);
$left: if-rtl(left, right);
$right: if-rtl(right, left);

@function side-values($values) {
  @if $dir == ltr and length($values) >= 4 {
    // Reorder right and left positions in list
    @return nth($values, 1) nth($values, 4) nth($values, 3) nth($values, 2);
  }
  @else {
    @return $values;
  }
}
  
@function corner-values($values) {
  @if $dir == rtl and length($values) > 1 {
    // Reorder right and left positions in list
    @if length($values) == 2 {
        @return nth($values, 2) nth($values, 1);
    }
    @else if length($values) == 3 {
        @return nth($values, 2) nth($values, 1) nth($values, 2) nth($values, 3);
    }
    @else {
        @return nth($values, 2) nth($values, 1) nth($values, 4) nth($values, 3);
    }
  }
  @else {
    @return $values;
  }
}
  
@mixin if-ltr { 
  @if $dir != rtl {
    @content;
  }
}

@mixin if-rtl {
  @if $dir == rtl {
    @content;
  }
}

@mixin direction-mode {
    @each $direction in (ltr, rtl) {
        $dir: $direction;
        $dirOpposite: if-rtl(ltr, rtl);
        $left: if-rtl(left, right);
        $right: if-rtl(right, left);

        [data-direction='#{$direction}'] {
            @content;
        }
    }
}

/*#endregion */